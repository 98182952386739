@import 'style/_mixin.sass'

.root
  font-weight: bold
  color: $color-accent
  text-decoration: underline

  +hover-or-focus
    color: darken($color-accent, 5%)

  +active
    color: darken($color-accent, 5%)
