@import 'style/_mixin.sass'

.root
  position: relative

.input,
.pre
  ///R
  height: auto
  white-space: pre-line
  word-break: break-word

.pre
  opacity: 0
  visibility: hidden
  pointer-events: none

.input
  @include stretch
  ///R
  overflow-x: hidden
  overflow-y: auto
  resize: none
