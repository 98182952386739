@import 'style/_mixin.sass'

.section
  position: relative

.message
  width: 50%

.content
  // padding-top: 10vh
  // padding-bottom: 10vh

.itemTitle
  font-weight: bold

.zigzag
  width: 40px
  padding: 10px 0

.hero
  +position-center-vertical
  ///R
  right: 0
  display: block
  width: 50%

.bg
  background-color: #fafafa

@include media-wider-than(sm)
  // .content
  //   padding-top: 20vh
  //   padding-bottom: 30vh

.heroMessage
  max-width: 650px

.center
  text-align: center

@mixin grid-gutter($gutter-vertical, $gutter-horizontal)
  margin: (-$gutter-vertical) (-$gutter-horizontal)

  .image,
  .itemContent
    margin: $gutter-vertical $gutter-horizontal

.item
  margin-bottom: 4em

.image
  display: block
  width: 100%
  +aspect-ratio(16, 9)
  ///R
  margin-bottom: 2em
  // flex-shrink: 0
  // width: 400px
  // margin-right: 32px
  background-color: #eee

.itemContent
  flex: 1
  // padding: 48px
  // background-color: #1c1b20
  // color: #fff

.bullet
  line-height: 1.75em

@include media-wider-than(md)
  .item
    display: flex
    align-items: stretch
    flex-wrap: wrap
    @include grid-gutter(160px, 64px)

    &:nth-child(2n)
      .image
        order: 1

  .image
    flex: 1
    padding: 0
