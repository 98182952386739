@import 'style/_mixin.sass'

.root
  display: inline-block
  border-style: solid
  border-top-color: transparent
  border-left-color: transparent
  border-bottom-color: transparent
  border-radius: 50%
  vertical-align: middle
  animation: rotate 1s linear both infinite

.center
  @include position-center
  ///R
  animation-name: rotateOnCenter

@keyframes rotate
  0%
    transform: rotate(0)

  100%
    transform: rotate(359deg)

@keyframes rotateOnCenter
  0%
    transform: translate(-50%, -50%) rotate(0)

  100%
    transform: translate(-50%, -50%) rotate(359deg)
