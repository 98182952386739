@import 'theme'

=stretch-custom($value, $position: absolute)
  position: $position
  top: $value
  left: $value
  right: $value
  bottom: $value

=stretch($position: absolute)
  +stretch-custom(0, $position)

=vertical-align-middle
  display: inline-block
  vertical-align: middle

=vertical-align-content
  +vertical-align-middle
  ///R
  position: relative
  white-space: normal

=vertical-align($height: 100%)
  height: $height
  white-space: nowrap

  &:before
    +vertical-align-middle
    ///R
    content: ''
    width: 0
    height: 100%

=text-selection-none
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

=active
  &:active
    @content

=hover
  &:hover
    @content

=focus
  &:focus
    @content

=hover-and-focus
  +hover
  +focus
  @content

=hover-or-focus
  &:hover,
  &:focus
    @content

=disabled
  &.disabled,
  &[disabled]
    @content

=not-disabled
  &:not(.disabled):not([disabled])
    @content

@function button-focus-shadow($shadow-color)
  @return 0 2px 4px 2px $shadow-color

@function fade($color, $amount)
  @return (fade-out($color, 1 - $amount))

@function transition($name, $duration: 0.25s, $delay: 0s)
  @return $name $duration ease-in-out $delay

=fade-in
  opacity: 1
  visibility: visible

=fade-out
  opacity: 0
  visibility: hidden

=fadeable($visible: false, $duration: 0.25s, $delay: 0s)
  @if $visible == false
    +fade-out
  @else
    +fade-in
    ///R
    transition: transition(opacity, $duration, $delay), transition(visibility, $duration, $delay)

=ellipsis
  white-space: nowrap
  overflow: hidden
  -ms-text-overflow: ellipsis
  -o-text-overflow: ellipsis
  text-overflow: ellipsis

=ellipsis-multiline($line-count: 2, $font-size: 14px, $line-height: 1.4)
  display: block
  display: -webkit-box
  width: 100%
  max-height: multiline-height($line-count, $font-size, $line-height)
  font-size: $font-size
  line-height: $line-height
  -webkit-line-clamp: $line-count
  line-clamp: $line-count
  box-orient: vertical
  -moz-box-orient: vertical
  -webkit-box-orient: vertical
  overflow: hidden
  -ms-text-overflow: ellipsis
  -o-text-overflow: ellipsis
  text-overflow: ellipsis

@function multiline-height($line-count: 2, $font-size: 14px, $line-height: 1.4)
  @return $font-size * $line-height * $line-count

=position-center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

=position-center-vertical
  position: absolute
  top: 50%
  transform: translateY(-50%)

=position-center-horizontal
  position: absolute
  left: 50%
  transform: translateX(-50%)

=media-wider-than($breakpoint)
  @media only screen and (min-width: #{horizontal-breakpoint($breakpoint)})
    @content

=media-taller-than($breakpoint)
  @media only screen and (min-height: #{vertical-breakpoint($breakpoint)})
    @content

@function horizontal-breakpoint($breakpoint)
  @if map-has-key($horizontal-breakpoints, $breakpoint)
    @return inspect(map-get($horizontal-breakpoints, $breakpoint))
  @else
    @error "Unfortunately, no value could be retrieved from `#{$horizontal-breakpoints}`. Please make sure it is defined in `$horizontal-breakpoints` map."
    @return null

@function vertical-breakpoint($breakpoint)
  @if map-has-key($vertical-breakpoints, $breakpoint)
    @return inspect(map-get($vertical-breakpoints, $breakpoint))
  @else
    @error "Unfortunately, no value could be retrieved from `#{$vertical-breakpoints}`. Please make sure it is defined in `$vertical-breakpoints` map."
    @return null

=aspect-ratio($width, $height)
  padding-bottom: 100% / ($width / $height)

=clearfix
  zoom: 1

  &:before,
  &:after
    content: ''
    display: table

  &:after
    clear: both

=scrollable
  overflow-x: hidden
  overflow-y: auto

@function button-outline-shadow($border-color, $border-width: 1px)
  @return inset 0 0 0 $border-width $border-color

=button-outline($border-color, $border-width: 1px)
  box-shadow: button-outline-shadow($border-color, $border-width)

=button-fill($color, $background-color)
  color: $color
  background-color: $background-color

=placeholder
  &::-webkit-input-placeholder
    @content

  &:-moz-placeholder
    @content

  &::-moz-placeholder
    @content

  &:-ms-input-placeholder
    @content

=border-radius
  border-radius: 2px

@function strip-unit($number)
  @if type-of($number) == 'number' and not unitless($number)
    @return $number / ($number * 0 + 1)

  @return $number
