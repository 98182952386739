@import 'style/_mixin.sass'

$loader-size: 16px
$loader-border-size: 2px

.root
  position: relative
  // +fadeable
  display: none
  z-index: 1

.visible
  // +fade-in
  display: block

.overlay
  +stretch
  ///R
  background-color: #fff

.fixed
  position: fixed

.content
  font-weight: bold

.center
  .content
    +position-center
    ///R
    font-weight: bold
