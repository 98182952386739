$horizontal-breakpoints: (xs: 480px, sm: 768px, md: 990px, lg: 1280px, xl: 1600px)
$color-pink: #F42F63
$color-dark-0: #0F0E0E
$color-dark-1: #575757

$color-light: #fff
$color-light-0: #fff
$color-light-1: #f1f1f1

$color-invalid-border: $color-pink
$color-invalid-background: rgba(244,47,99,0.1)
$color-invalid-text: $color-pink

// $color-dark: #1d2026
$color-dark: #1c1b20
$color-gray: #f0f2f7

$label-height: 20px
$field-height: 48px

$header-z-index: 1
$preloader-z-index: $header-z-index + 1

$color-accent: #fd0037
$color-accent: #009e7e
$color-accent: #43d8c9
$color-primary: #1c1b20
$color-secondary: #999
$color-ternary: #c6c8cc

$header-height-xs: 72px
$header-height-sm: 96px
