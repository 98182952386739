@import 'style/_mixin.sass'

@mixin style
  @content

  &:-webkit-autofill
    @content

.root
  display: block
  background-color: transparent
  box-sizing: border-box
  transition: transition(border)

  @include style
    $padding: 8px
    width: 100%
    height: $field-height
    padding: $padding 0
    font: inherit
    font-size: 1.25em
    line-height: $field-height - ($padding * 2)
    color: $color-dark-0
    border: none
    border-bottom: 2px solid $color-light-1

  @include placeholder
    color: #d0d0d0

  +hover
    border-color: $color-accent

  +focus
    border-color: $color-accent

  &[disabled]
    color: fade($color-dark-0, 0.4)
    -webkit-text-fill-color: fade($color-dark-0, 0.1)

  &.invalid
    border-color: $color-invalid-border
