@import 'style/_mixin.sass'

@value primaryLoadingColor #{"" + $color-light}
@value secondaryLoadingColor #{"" + $color-primary}

@value normalSize #{$field-height}
@value largeSize #{$field-height * 1.5}

=button-size($height)
  min-width: $height
  height: $height
  line-height: $height + 4

=horizontal-padding($padding)
  padding: 0 $padding

.root
  position: relative
  display: inline-block
  background: none
  color: inherit
  font-family: 'Bebas Neue', sans-serif
  font-size: 1.25em
  white-space: nowrap
  text-align: center
  text-decoration: none
  text-transform: uppercase
  letter-spacing: 4px
  outline: 0
  cursor: pointer
  @include border-radius
  @include text-selection-none
  @include button-size($field-height)
  @include horizontal-padding(20px)

  @include disabled
    cursor: default

.large
  @include horizontal-padding($field-height)
  @include button-size($field-height * 1.5)

.block
  display: block
  width: 100%

.content
  @include fadeable(true)

.loading
  .content
    @include fade-out

.loader
  @include position-center

.primary
  @include button-fill($color-light, $color-accent)

  @include not-disabled
    @include hover-or-focus
      @include button-fill($color-light, darken($color-accent, 5%))

    @include active
      @include button-fill($color-light, darken($color-accent, 5%))

.secondary
  box-shadow: inset 0 0 0 3px $color-dark
  @include button-fill($color-dark, $color-light)

  @include not-disabled
    @include hover-or-focus
      box-shadow: inset 0 0 0 3px $color-accent
      @include button-fill($color-accent, $color-light)

    @include active
      box-shadow: inset 0 0 0 3px $color-accent
      @include button-fill($color-accent, $color-light)
