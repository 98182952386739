@import 'style/_mixin.sass'

.root
  width: 100%
  max-width: 1200px
  min-width: 320px
  margin-left: auto
  margin-right: auto
  padding-left: 20px
  padding-right: 20px

.full
  max-width: none

@include media-wider-than(sm)
  .root
    padding-left: 40px
    padding-right: 40px
