@import 'style/_mixin.sass'

$words: 'passionate', 'developer', '+', 'solution', 'architect', '.'
$column-count: 5

$word-count: length($words)

$words-slide-up-start-at: 0.5s
$words-slide-up-duration: 1s
$words-visible-duration: 1.5s
$words-slide-down-duration: 1s
$words-delay-duration: 0.15s
$words-slide-duration: $words-slide-up-duration + $words-visible-duration + $words-slide-down-duration

@value words #{$words}
@value duration #{strip-unit($words-slide-up-start-at + $words-slide-duration)}
@value columnCount #{$column-count}

.root
  @include stretch(fixed)
  ///R
  z-index: $preloader-z-index
  pointer-events: none

.content
  @include position-center
  ///R
  width: 100%
  padding: 0.5em
  font-weight: bold
  font-size: 2em
  text-align: center

.column,
.word
  will-change: transform

.columns
  overflow: hidden
  +stretch
  +clearfix

.column
  position: relative
  width: (100% / $column-count)
  height: 100%
  float: left
  background-color: $color-primary
  animation: columnSlide 1s ease-in-out both

  @for $index from 0 through $column-count
    &:nth-child(#{$index})
      animation-delay: $words-slide-up-start-at + $words-slide-up-duration + $words-visible-duration + 0.1s * $index
      animation-duration: $words-slide-down-duration

@keyframes columnSlide
  0%
    transform: translateY(0)

  100%
    transform: translateY(100%)

.word
  position: relative
  display: inline-block
  line-height: 1
  overflow: hidden

  .wordContent
    display: inline-block
    animation: wordSlide 1s ease-in-out both

  &:not(:first-child):not(:last-child)
    padding-left: 0.25em

  @for $index from 0 through $word-count
    &:nth-child(#{$index})
      .wordContent
        animation-delay: $words-slide-up-start-at + $words-delay-duration * $index
        animation-duration: $words-slide-duration - $words-delay-duration * $index

@keyframes wordSlide
  $translate: 200%
  $rotate: 15deg

  0%
    transform-origin: left top
    transform: translateY($translate) skewY($rotate)

  #{strip-unit($words-slide-up-duration) * 100% / strip-unit($words-slide-duration)}
    transform: translateY(0) skewY(0deg)

  #{strip-unit($words-slide-up-duration + $words-visible-duration) * 100% / strip-unit($words-slide-duration)}
    transform: translateY(0) skewY(0deg)

  100%
    transform-origin: right bottom
    transform: translateY($translate) skewY(-$rotate / 2)

@include media-wider-than(sm)
  .root
    white-space: nowrap

  .content
    width: auto
    max-width: none
    padding: 0
    font-size: 2em
