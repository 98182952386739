@import 'style/_mixin.sass'

.root
  position: relative
  display: inline-block

.h1,
.h2,
.h3
  font-family: 'Bebas Neue', sans-serif
  font-weight: normal

.h1
  font-size: 2.5em

.h2
  font-size: 1.5em

.h3
  font-size: 1.25em

.block
  display: block

.underline
  position: relative
  z-index: 0

  &:before
    content: ''
    position: absolute
    bottom: 0.1em
    left: -0.1em
    display: block
    width: 25%
    min-width: 100px
    border-bottom: 0.5em solid transparent
    z-index: -1

=color($color)
  color: $color

  &.underline
    &:before
      border-bottom-color: $color-accent

.primary
  +color($color-primary)

.secondary
  +color($color-secondary)

.ternary
  +color($color-ternary)

.accent
  +color($color-accent)

.light
  +color($color-light)

@include media-wider-than(sm)
  .h1
    font-size: 4em

  .h2
    font-size: 2.5em

  .h3
    font-size: 1.75em

  .p
    font-size: 1.25em
    line-height: 1.75em

  .small
    font-size: 0.75em
