@import 'style/_mixin.sass'

$sizes: 1, 2, 3, 4, 6, 8, 12, 16, 24, 32
$space: 8px

=sizes($device, $magnitude, $type)
  @each $size in $sizes
    .#{$device}#{$type}#{$size}
      #{$type}-bottom: $space * $size * $magnitude

@include sizes(xs, 1, padding)
@include sizes(xs, 1, margin)

@include media-wider-than(xs)
  @include sizes(sm, 1, padding)
  @include sizes(sm, 1, margin)

@include media-wider-than(sm)
  @include sizes(md, 1.5, padding)
  @include sizes(md, 1.5, margin)

@include media-wider-than(md)
  @include sizes(lg, 2, padding)
  @include sizes(lg, 2, margin)

@include media-wider-than(lg)
  @include sizes(xl, 2.5, padding)
  @include sizes(xl, 2.5, margin)
